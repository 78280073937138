<template>
  <div class="container">
    <!-- Filter buttons -->
    <div class="filter-buttons">
      <a-button
        v-for="category in categories"
        :key="category.id"
        type="default"
        class="mb-3"
        :class="{ active: selectedCategory === category.tag }"
        @click="filterCategory(category.tag)"
      >
        {{ category.tag }}
      </a-button>
    </div>

    <!-- Article Grid or "No Articles" message -->
    <div v-if="filteredArticles.length > 0">
      <a-row :gutter="16" class="article-list">
        <a-col
          v-for="article in paginatedArticles"
          :key="article.id"
          :xs="24"
          :sm="24"
          :xl="8"
          class="mt-4"
        >
          <div class="card custom-card" @click="handleDetail(article.id)">
            <img :src="backendUrl + article.image" class="card-img-top" alt="Olahraga" />

            <!-- <div :class="$style['overlay']"></div> -->
            <div class="px-0 px-sm-5 px-xl-0">
              <h5 class="card-description" style="color: #831A53">
                {{ formatDate(article.createdAt) }}
              </h5>
              <h5 class="card-title">{{ truncateTitle(article.title) }}</h5>
              <h5 class="card-description">{{ truncateDescription(article.body) }}</h5>
              <a-button type="primary" class="custom-button">
                <img
                  src="../../assets/img/baca.png"
                  alt=""
                  style="width: 24px;height: 20px;margin-right: 10px;"
                />
                <p style="font-size: 24px">Baca</p></a-button
              >
            </div>
          </div>
        </a-col>
      </a-row>

      <!-- Pagination -->
      <div class="pagination">
        <a-pagination
          :current="currentPage"
          :page-size="pageSize"
          :total="filteredArticles.length"
          @change="onPageChange"
        />
      </div>
    </div>
    <!-- No articles message -->
    <div v-else>
      <p>Artikel tidak ditemukan.</p>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, computed } from 'vue'
import { getListNews, getAllTagNews } from '../../services/axios/pulih'
import { useRouter } from 'vue-router'

export default {
  setup() {
    const articles = ref([])
    const currentPage = ref(1)
    const pageSize = ref(6)
    const selectedCategory = ref('Semua')
    const categories = ref(['Semua', 'Kanker Paru', 'COVID 19', 'Aktifitas Fisik'])
    const backendUrl = ref('https://pulih-api-prod.digitala.id/')
    const router = useRouter()

    onMounted(async () => {
      try {
        const response = await getListNews()
        const res = await getAllTagNews()
        articles.value = response
        categories.value = [{ tag: 'Semua', id: 'all' }, ...res] // Add 'Semua' as the first category
        console.log(res, 'inialltag')
      } catch (error) {
        console.error('Error fetching articles:', error)
      }
    })

    const handleDetail = id => {
      router.push(`/article/${id}`)
    }

    const filteredArticles = computed(() => {
      if (selectedCategory.value === 'Semua') {
        return articles.value
      }
      return articles.value.filter(article =>
        article.tags.some(tag => tag.tag === selectedCategory.value),
      )
    })

    const paginatedArticles = computed(() => {
      const start = (currentPage.value - 1) * pageSize.value
      return filteredArticles.value.slice(start, start + pageSize.value)
    })

    const filterCategory = category => {
      selectedCategory.value = category
      currentPage.value = 1 // Reset to the first page when filtering
    }

    const onPageChange = page => {
      currentPage.value = page
    }

    const truncateTitle = (text, maxLength = 35) => {
      if (text.length > maxLength) {
        return text.substring(0, maxLength) + '...'
      }
      return text
    }

    const truncateDescription = (text, maxLength = 100) => {
      if (text.length > maxLength) {
        return text.substring(0, maxLength) + '...'
      }
      return text
    }
    const formatDate = dateString => {
      const options = { year: 'numeric', month: 'long', day: 'numeric', weekday: 'long' }
      return new Date(dateString).toLocaleDateString('id-ID', options)
    }
    return {
      formatDate,
      handleDetail,
      backendUrl,
      articles,
      currentPage,
      pageSize,
      selectedCategory,
      categories,
      filteredArticles,
      paginatedArticles,
      filterCategory,
      onPageChange,
      truncateTitle,
      truncateDescription,
    }
  },
}
</script>

<style scoped>
.container {
  padding: 20px;
}

.filter-buttons {
  margin-bottom: 20px;
  display: flex;
  gap: 10px;
  overflow: scroll;
}

.filter-buttons .active {
  background-color: #800152;
  color: white;
}

.article-list {
  margin-top: 20px;
}
.custom-card {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  border-radius: 17px;
  padding: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  height: 520px;

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: row;
    height: 300px;
  }
  @media (max-width: 992px) {
    height: 480px;
  }
  @media (max-width: 768px) {
    height: 420px;
    padding: 15px;
  }
  @media (max-width: 576px) {
    display: flex;
    flex-direction: column;
    height: 420px;
    padding: 10px;
  }
}
.custom-card img {
  width: 100%;
  object-fit: cover;
  height: 200px;
  @media (max-width: 1024px) {
    display: flex;
    flex-direction: row;
    width: 40%;
    height: 100%;
  }
  @media (max-width: 576px) {
    width: 100%;
    height: auto;
    object-fit: cover;
    max-height: 200px;
  }
  /* Sesuaikan dengan kebutuhan, bisa juga height: 100% jika perlu */
}

.custom-button {
  position: absolute !important;
  display: flex !important;
  align-items: baseline;
  justify-content: center;
  width: 100%;
  max-width: 308px;
  height: 50px !important;
  bottom: 20px;
  background-color: #3d1053 !important;
  color: white;
  font-size: 18px;

  @media (max-width: 1440px) {
    max-width: 308px;
    height: 45px;
    font-size: 16px;
  }
  @media (max-width: 1024px) {
    max-width: 248px;
    bottom: 80px;
  }
  @media (max-width: 768px) {
    max-width: 178px;
    height: 45px;
    bottom: 150px;
    font-size: 12px;
  }

  @media (max-width: 576px) {
    max-width: 180px;
    height: 50px !important;
    font-size: 14px;
    bottom: 10px;
  }
}

.card-title {
  font-weight: bold;
  font-size: 27px;
  text-align: left;

  @media (max-width: 1024px) {
    font-size: 24px;
  }

  @media (max-width: 576px) {
    font-size: 20px;
  }
}

.card-description {
  font-size: 16px;
  text-align: left;
  margin-top: 15px;

  @media (max-width: 768px) {
    margin-top: 10px;
  }

  @media (max-width: 576px) {
    font-size: 12px;
    margin-top: 5px;
  }
}

.custom-card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.article-content {
  padding: 15px;
}

.article-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.article-description {
  font-size: 14px;
  color: #666;
}

.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
</style>
